<template>
    <div class="account_user">

        <div class="page-header">
            <el-row>
                <el-col :span="24" align="right">
                    <el-button type="primary" round @click="addUser()" icon="el-icon-circle-plus-outline">{{ $t('data.account.addUse') }}</el-button>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20">
            <el-col :span="24">
                <el-card shadow="always">
                    <el-table
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                        :label="$t('data.account.table.photo')"
                        width="80">
                            <template #default="scope">
                            <img :src="scope.row.avatar" alt="" class="table-user-photo">
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="id"
                        :label="$t('data.account.table.id')"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="account"
                        :label="$t('data.account.table.userName')">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        :label="$t('data.account.table.name')">
                        </el-table-column>
                        <el-table-column
                        prop="role"
                        :label="$t('data.account.table.role')">
                        </el-table-column>
                        <el-table-column
                        prop="branch.name"
                        :label="$t('data.account.table.branchName')">
                        </el-table-column>
                        <el-table-column :label="$t('data.public.action')" align="right" width="280">
                            <template #default="scope">
                                <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)">{{ $t('data.public.edit') }}</el-button>
                                <el-button
                                size="mini"
                                type="primary"
                                @click="handleClick(scope.$index, scope.row)">{{ $t('data.public.view') }}</el-button>
                                <el-popconfirm
                                confirm-button-type="danger"
                                @confirm="handleDelete(scope.row, scope.$index)"
                                title="確定要刪除嗎？">
                                    <template #reference>
                                        <el-button type="danger" size="small">{{ $t('data.public.delete') }}</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                    <table-pagination :page="page" @updatePage="updatePage"></table-pagination>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog :title="$t('data.account.userInfo')" v-model="dialogFormVisible">
            <el-form :model="form" :disabled="isDisabled">
                <el-form-item :label="$t('data.account.id')" :label-width="formLabelWidth" v-if="isShow">
                    <el-input v-model="form.id" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item :label="$t('data.account.username')" :label-width="formLabelWidth">
                    <el-input v-model="form.account" autocomplete="off" :disabled="form.id ? true : false"></el-input>
                </el-form-item>
                <el-form-item :label="$t('data.account.name')" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('data.account.icon')" :label-width="formLabelWidth">
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        list-type="picture-card"
                        :on-change="handleChange"
                        :auto-upload="false"
                        :show-file-list="false">
                        <img v-if="form.avatar" :src="form.avatar" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <!-- <el-input v-model="form.school" autocomplete="off">
                        <template #append>
                            <el-button style="border-right: 1px solid #DCDFE6;">Cancel</el-button>
                            <el-button>Browse</el-button>
                        </template>
                    </el-input> -->
                </el-form-item>
                <el-form-item :label="$t('data.account.password')" :label-width="formLabelWidth" v-if="this.$store.getters.isAdminAuth">
                    <el-input v-model="form.password" autocomplete="off" show-password></el-input>
                </el-form-item>
                <el-form-item :label="$t('data.account.role')" :label-width="formLabelWidth">
                    <el-select v-model="form.role" placeholder="Select">
                        <el-option :label="$t('data.role.Manager')" value="manager"></el-option> 
                        <el-option :label="$t('data.role.Officer')" value="officer"></el-option>
                        <el-option :label="$t('data.role.SuperAdmin')" value="admin" :disabled="true"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('data.account.branchName')" :label-width="formLabelWidth">
                    <el-select v-model="form.branch.id" placeholder="Select">
                        <el-option v-for="(item,index) in branchList" :key="index" :label="item.name" :value="item.id"></el-option> 
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('data.public.action')" :label-width="formLabelWidth">
                    <el-switch v-model="form.is_enable" />
                </el-form-item>
                <el-form-item v-if="isShow" :label="$t('data.account.createTime')" :label-width="formLabelWidth">
                    <p>{{ form.created_at }}</p>
                </el-form-item>
                <el-form-item v-if="isShow" :label="$t('data.account.updateTime')" :label-width="formLabelWidth">
                    <p>{{ form.updated_at }}</p>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">{{$t('data.public.cancel')}}</el-button>
                <el-button type="primary" @click="submitForm()" :disabled="isDisabled">{{$t('data.public.save')}}</el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { userList,uploadImage,branchesList,addUser,editUser,delUser } from '../../api/index'
import tablePagination from "@/components/tablePagination/TablePagination"
  export default {
      components:{
        tablePagination
      },
      data() {
        return {
          page: {},
          tableData: [],
          dialogFormVisible: false,
          formLabelWidth: '140px',
          form: {},
          isDisabled: false,
          isShow: false,
          branchList: []
        }
      },
      mounted() {
        this.initPageData();
      },
      methods: {
        initPageData(data){
          let apiData = {}
          if(data){
            Object.assign(apiData,data)
          }
          userList({apiData}).then( res => {
            this.tableData = res.data;
            this.page = res.meta.page;
          })
          branchesList({}).then( res => {
            this.branchList = res.data;
          })
        },
        addUser(){
            this.dialogFormVisible = true;
            this.isDisabled=false;
            this.isShow = false;
            this.form = {
                account: '',
                name: '',
                role: '',
                password: '',
                avatar: '',
                is_enable: false,
                branch_id: null,
                branch:{id: null}
            }
        },
        handleEdit(index, row) {
            this.dialogFormVisible = true;
            this.isDisabled=false;
            this.isShow = true;
            this.form = {...row};
            if(this.$store.getters.isAdminAuth){
                this.form.password = ''
            }
        },
        handleClick(index, row){
            this.dialogFormVisible = true;
            this.isDisabled=true;
            this.isShow = true;
            this.form = row;
        },
        handleDelete(row, index) {
            let apiData = {id: row.id};
            delUser({apiData}).then( res => {
                ElMessage.success({
                    message: 'Success',
                    type: 'success'
                });
                this.initPageData();
            })
        },
        handleChange(file, fileList){
            let param = new FormData();
            param.append('image', file.raw)
            param.append('type', 'logo')
            let apiData = param
            uploadImage({apiData}).then( res => {
                this.form.avatar = res.data.url;
            })
        },
        updatePage(data){
            this.initPageData(data);
        },
        submitForm(){
            if(this.form.branch_id === null || this.form.branch_id === undefined){
                this.form.branch_id = this.form.branch.id;
            }
            let apiData = this.form;
            if(apiData.id){
                editUser({apiData}).then( res => {
                     ElMessage.success({
                        message: 'Success',
                        type: 'success'
                    });
                    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                    if(userInfo.info.id === res.data.id){
                        userInfo.info = res.data
                        localStorage.setItem("userInfo", JSON.stringify(userInfo));
                        localStorage.setItem("ms_username", userInfo.info.branch.name);
                        this.$router.go(0);
                    } else {
                        this.dialogFormVisible = false;
                        this.initPageData();
                    }
                 })
            }else{
                addUser({apiData}).then( res => {
                    ElMessage.success({
                        message: 'Success',
                        type: 'success'
                    });
                    this.dialogFormVisible = false;
                    this.initPageData();
                })
            }
             
        }
      }
    }
</script>
<style>
.avatar-uploader .el-upload--picture-card{
    margin-bottom: 20px !important;
}
.avatar-uploader .el-upload img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>